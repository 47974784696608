<template>
  <div>
    <h2
      class="text-2xl -mt-4 md:mt-2 mb-6 md:block md:text-left innerTransition__title"
    >
      {{ t("getStarted.healthAndWellness.chooseTopic") }}
    </h2>

    <div
      class="bg-white divide-y-2 rounded-lg shadow-default overflow-hidden innerTransition__container"
    >
      <CommonRippleBox
        ripple-position="center-left"
        :initial-opacity="0.9"
        class="rounded-none"
      >
        <NuxtLinkLocale
          :locale="switchBlogLocale()"
          to="/tests/health-and-wellness"
          class="p-5 py-6 flex flex-row flex-nowrap items-center gap-2"
        >
          <BaseTypography variant="span" class="flex-1 font-semibold"
            >All Health & Wellness tests</BaseTypography
          >
          <NuxtImg
            src="/icons/mask.svg"
            alt="mask icon"
            width="30"
            height="30"
          />
        </NuxtLinkLocale>
      </CommonRippleBox>
      <CommonRippleBox
        v-for="category in categories"
        :key="category.slug"
        ripple-position="center-left"
        :initial-opacity="0.9"
        class="rounded-none"
      >
        <NuxtLinkLocale
          :locale="switchBlogLocale()"
          :to="`/tests/health-and-wellness?categories=${category.slug}`"
          class="p-5 py-6 flex flex-row flex-nowrap items-center gap-2"
        >
          <BaseTypography variant="span" class="flex-1 font-semibold">{{
            category.title
          }}</BaseTypography>
          <NuxtImg
            src="/icons/mask.svg"
            alt="mask icon"
            width="30"
            height="30"
          />
        </NuxtLinkLocale>
      </CommonRippleBox>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HttpResponse } from "~/types/general";
import type { HealthAndWellnessCategory } from "../types";

const { t } = useI18n();

/**
 * Fetch all necessary api data.
 */
const categoriesResults = await useFetchSafely<
  HttpResponse<HealthAndWellnessCategory[]>
>("/sub-categories/health-and-wellness", {
  useErrorBoundary: false
});

/**
 * Fetch categories list from api results.
 */
const categories = computed(() => categoriesResults.data.value?.data ?? []);
</script>

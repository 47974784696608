<template>
  <div
    class="bg-white divide-y-2 rounded-lg shadow-default overflow-hidden"
  >
    <div>
      <div class="relative flex flex-wrap items-start p-5">
        <div class="flex h-11 w-11 flex-none items-start justify-center">
          <NuxtImg
            loading="lazy"
            :src="selectedService?.icon.permalink"
            class="w-9"
            alt="clinic icon"
            width="36"
            height="36"
          />
        </div>

        <div
          v-if="everywhereNurseServiceIsFormActive"
          class="flex flex-col flex-1 mt-1 mr-2 md:mx-3"
        >
          <div class="font-bold">
            {{
              everywhereNurseServiceLocationAvailabilityStatus === "idle"
                ? selectedService?.title
                : `${$t("getStarted.everywhereNurseService.searchErrorTitle", {
                    searchTerm: everywhereNurseServiceSearchTerm,
                  })}`
            }}
          </div>
          <div class="my-2 text-sm leading-6 md:mr-8 text-left">
            {{
              everywhereNurseServiceLocationAvailabilityStatus === "idle"
                ? selectedService?.short_description
                : $t("getStarted.everywhereNurseService.searchErrorDescription")
            }}
          </div>
        </div>
        <div v-else class="flex-1">
          <div class="font-bold">
            {{
              $t("getStarted.everywhereNurseService.searchSuccessTitle", {
                searchTerm: everywhereNurseServiceSearchTerm,
              })
            }}
          </div>
          <BaseButton
            :text="$t('getStarted.everywhereNurseService.buttons.chooseTest')"
            class="ml-auto mt-12"
            @click="handleNavigateToEverywhereNurseServiceTests"
          />
        </div>

        <form
          v-if="everywhereNurseServiceIsFormActive"
          method="post"
          class="flex flex-row flex-1 basis-full flex-wrap gap-2 mt-6"
          @submit.prevent="handleEverywhereNurseServiceSubmit"
        >
          <ClinicMapSearchBox
            showSearchBox
            button-type="submit"
            :reset-scroll="false"
            name="town_or_postcode"
            class="basis-full mx-0 -mb-4"
            :loading="everywhereNurseServiceMutationStatus === 'pending'"
            disable-default-css
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Loader } from "@googlemaps/js-api-loader";

const route = useRoute();
const mapStore = useMapStore();
const globalStore = useGlobalStore();

const getStartedModalStore = useGetStartedModalStore();
const checkoutStore = useCheckoutStore();

const selectedService = computed(() => getStartedModalStore.selectedService);

const { data: products } = await useFetchProductList({
  "service_type[0]": "everywhere-nurse-service",
});
mapStore.getTests(products.value.data);

// Set clinic id to store.
checkoutStore.setSelectedClinicId("everywhere");

const everywhereNurseServiceSearchTerm = ref<string>(String(route.query.searchTerm));
const everywhereNurseServiceLocationAvailabilityStatus = ref("idle");
const everywhereNurseServiceMutationStatus = ref("idle");
const everywhereNurseServiceIsFormActive = computed(() =>
  ["idle", "unavailable"].includes(
    everywhereNurseServiceLocationAvailabilityStatus.value
  )
);

async function handleEverywhereNurseServiceSubmit(e: Event) {
  const loader = new Loader({
    apiKey: useRuntimeConfig().public.apiGoogleMapKey,
    version: "weekly",
    libraries: ["places"],
  });
  const { Geocoder } = await loader.importLibrary("geocoding");
  const geoCoder = new Geocoder();

  const formData = new FormData(e.target as HTMLFormElement);
  const payload = Object.fromEntries(formData.entries());
  if (!payload.town_or_postcode) return;

  everywhereNurseServiceMutationStatus.value = "pending";

  const {
    results: [result],
  } = await geoCoder
    .geocode({
      address: String(payload.town_or_postcode),
    })
    .catch(() => {
      everywhereNurseServiceLocationAvailabilityStatus.value = "unavailable";
      everywhereNurseServiceSearchTerm.value = String(payload.town_or_postcode);
      everywhereNurseServiceMutationStatus.value = "idle";

      return { results: [] };
    });

  const position = {
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng(),
  };

  const { data } = await useFetchSafely<any>(`/check-everywhere-service`, {
    method: "POST",
    body: position,
  });

  everywhereNurseServiceSearchTerm.value = String(payload.town_or_postcode);
  everywhereNurseServiceLocationAvailabilityStatus.value = data.value?.available
    ? "available"
    : "unavailable";
  everywhereNurseServiceMutationStatus.value = "idle";
}

async function handleNavigateToEverywhereNurseServiceTests() {
  globalStore.setClinicMapSearchText(everywhereNurseServiceSearchTerm.value);
  await navigateTo({
    query: {
      ...route.query,
      searchTerm: everywhereNurseServiceSearchTerm.value,
    },
  });

  openModal();
}

function openModal() {
  getStartedModalStore.navigate("show_tests");
  getStartedModalStore.open(
    // @ts-ignore
    {
      clinic_view: "map",
      ...selectedService.value,
      content: everywhereNurseServiceSearchTerm.value,
    }
  );
}
onMounted(() => {
  if (!route.query.searchTerm) return;
  openModal();
});
</script>

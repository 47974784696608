<template>
  <div>
    <GetStartedSelectedServiceCard
      v-if="selectedService"
      :service="selectedService"
    />

    <h2
      class="text-2xl my-8 md:mb-6 md:mt-2 md:block md:text-left innerTransition__title"
    >
      {{ serviceTitleText }}
      <button
        v-show="clinicTestProducts.length > 1"
        class="inline md:hidden"
        @click="isServiceModalOpen = true"
      >
        <NuxtImg loading="lazy" src="/icons/c-info-e.svg" alt="info" class="" />
      </button>
      <ProductInfoServiceModal
        :is-open="isServiceModalOpen"
        @on-modal-close="isServiceModalOpen = false"
      />
    </h2>

    <div
      v-if="selectionStep === 'service_selection'"
      class="bg-white divide-y-2 rounded-lg shadow-default overflow-hidden innerTransition__container"
    >
      <div v-for="(serviceList, index) in clinicTestProducts" :key="index">
        <CommonServiceTypeBlock
          @onClicked="handleSelectService"
          :serviceList="serviceList"
        />
      </div>
    </div>
    <div :key="selectedServiceSlug">
      <GetStartedSectionsTopicsClinicTest
        v-if="selectedServiceSlug === 'clinic-test'"
      />
      <GetStartedSectionsTopicsEverywhereNurseService
        v-if="selectedServiceSlug === 'everywhere-nurse-service'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GetStartedService } from "../types";

const { t } = useI18n();
const route = useRoute();
const getStartedModalStore = useGetStartedModalStore();

/**
 * Fetch all necessary api data.
 */
// const clinicTestResult = await useServiceListType();
const clinicTestResult = await useServiceListType();

/**
 * Fetch services list from api.
 */
const clinicTestProducts = computed<GetStartedService[]>(
  () => clinicTestResult.data.value?.data ?? []
);

/**
 * List of availables services.
 */
const SERVICES = ["clinic-test", "everywhere-nurse-service"] as const;
type ServiceSlug = (typeof SERVICES)[number];

/**
 * Currently focused section.
 */
const selectedSectionSlug = computed(() => route.params.section);

/**
 * Currently focused service.
 */
const selectedServiceSlug = computed(() => route.params.service as ServiceSlug);

/**
 * Finds the selected service entity from the service list array depending on the route params.
 */
const selectedService = computed(() =>
  clinicTestProducts.value.find(
    (service) => service.slug === selectedServiceSlug.value
  )
);

/**
 * Set selected service
 */
watch(selectedService, getStartedModalStore.setSelectedService, {
  immediate: true,
});

/**
 * Select a service and puts it under focus.
 * @param service - Target service
 */
async function handleSelectService(service: GetStartedService) {
  const locale = switchBlogLocale();
  const nextPath = `/${locale}/get-started/${selectedSectionSlug.value}/${service.slug}`;
  return navigateTo({
    path: nextPath,
  });
}

/**
 * Handle service modal state.
 */
const isServiceModalOpen = ref<boolean>(false);

/**
 * Computed value for service heading
 */
const serviceTitleText = computed(() => {
  switch (true) {
    case Boolean(selectedServiceSlug.value === "clinic-test"):
      return t("getStarted.serviceTypeSelection.clinicTest");

    case Boolean(selectedServiceSlug.value === "everywhere-nurse-service"):
      return t("getStarted.everywhereNurseService.serviceTitle");

    case Boolean(selectedServiceSlug.value):
      return t("getStarted.serviceTypeSelection.chooseTopic");

    default:
      return t("getStarted.serviceTypeSelection.default");
  }
});

/**
 * The current selection step of the flow.
 */
const selectionStep = computed<"service_selection" | "topic_selection">(() =>
  route.params.service ? "topic_selection" : "service_selection"
);
</script>
<template>
  <div class="w-full bg-white rounded-lg overflow-hidden shadow-card">
    <div class="px-4 py-1 flex justify-between relative bg-space-cadet">
      <span class="text-white text-[14px]">{{ titleTopicBox.title }}</span>
      <div
        class="flex items-center justify-center absolute bg-space-cadet rounded-full -bottom-[17px] right-4 w-10 h-10"
      >
        <NuxtImg
          loading="lazy"
          :src="titleTopicBox.icon.permalink"
          alt=""
          class="p-2"
          width="40"
          height="38"
        />
      </div>
    </div>

    <div class="h-[180px]">
      <div class="bg-terace-pool flex justify-center py-5 items-center">
        <div
          class="w-full flex justify-between items-center px-4 text-[19px] font-bold"
        >
          <span>{{ item.title }}</span>
          <span>{{ $formatCurrency(item.price) }}</span>
        </div>
      </div>
      <div class="mx-4 pt-8">
        <div class="text-sm" v-html="description"></div>
      </div>
    </div>
    <div class="mx-4 mb-4">
      <BaseButton
        text="Book Now"
        class="w-full py-6"
        @click="showDrawer"
      ></BaseButton>
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(["showDrawer"]);
const { $formatCurrency } = useNuxtApp();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  serviceTypeSelected: {
    type: Object,
  },
  titleTopicBox: {
    type: Object,
  },
});

function showDrawer() {
  emit("showDrawer");
}

const description = computed(() =>
  props.item.short_description.replace(
    "#TOPIC#",
    '<span class="font-bold">' + props.titleTopicBox.title + "</span>"
  )
);
</script>

<template>
  <div class="w-fit mx-auto">
    <CommonSpinner size="2rem" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const getStartedModalStore = useGetStartedModalStore();
const mapStore = useMapStore();

/**
 * Selected service entity.
 */
const selectedService = computed(() => getStartedModalStore.selectedService);

/**
 * Get clinic slug from route query params.
 */
const selectedClinicSlug = computed(() => route.query.slug);

/**
 * Fetch clinic detail by selected slug.
 */
const fetchClinicDetailBySlug = async () => {
  if (!selectedClinicSlug.value) return;
  
  const { data: clinicDetail } = await useFetchClinicDetailBySlug(
    selectedClinicSlug.value
  );
  const clinic = clinicDetail.value.data;
  if (!clinic) return;
  
  mapStore.setSelectedClinic({
    id: clinic.id,
    title: clinic.display_title,
    slug: clinic.slug,
  });
  await fetchClinicTests(clinic.id);
};

/**
 * Fetch all tests related to a specific clinic.
 * @param id - Clinic Id from where we should fetch tests.
 */
const fetchClinicTests = async (id: string) => {
  const { data: productsList } = await useFetchClinicTests(id);
  mapStore.getTests(productsList.value.data);
};

/**
 * Watch selected clinic slug change in order to update the products.
 */
watch(selectedClinicSlug, fetchClinicDetailBySlug);

/**
 * Auto call at first render
 */
await fetchClinicDetailBySlug();

function openModal() {
  getStartedModalStore.navigate("select_clinic");
  getStartedModalStore.open(
    // @ts-ignore
    {
      clinic_view: "map",
      ...selectedService.value,
      content: "",
    }
  );
}

async function abort() {
  navigateTo({
    params: {
      ...route.params,
      service: "",
    },
  });
}

onMounted(openModal);
watch(
  () => getStartedModalStore.isOpen,
  (isOpen, wasOpen) => {
    if (wasOpen && !isOpen) abort();
  }
);

onUnmounted(getStartedModalStore.close);
</script>

<template>
  <div>
    <CommonSliderDrawer @closeModal="closeModal" :openDrawer="open">
      <div class="flex h-full flex-col shadow-[-3px_0px_6px_rgba(0,0,0,0.29)]">
        <div
          class="bg-grey-light fixed top-0 z-10 md:max-w-[390px] w-full"
          :class="{
            'border-b border-light-silver': showClose && clinicLists.length > 0,
          }"
        >
          <div class="p-5">
            <h3 class="mr-2">
              {{ $t("clinic.sliderTitle", { testName: testName }) }}
            </h3>
          </div>
          <div class="md:mx-3" v-if="showClinicType == 'map'">
            <ClinicMapSearchBox
              :tabValue="true"
              :showClose="showClose"
              :showSearchBox="true"
              @onCloseClick="onCloseClicked"
              @onSearchClick="onSearchClicked"
              :isMobile="true"
            >
            </ClinicMapSearchBox>
            <ClinicTabView
              :tabValue="true"
              @onTabChange="onTabClicked"
            ></ClinicTabView>
          </div>
        </div>

        <div class="h-full bg-white">
          <ClinicMapRegionList
            :sliderHeight="
              showClinicType == 'list'
                ? 'height: calc(100vh - 50px); margin-top:7rem'
                : 'height: calc(100vh - 250px); margin-top:17rem;'
            "
            :clinicLists="clinicLists"
            :isMobile="isMobile"
            :showClose="showClose"
            v-if="!showGoogleMap"
          />
        </div>
        <div class="bg-white pb-12">
          <ClinicGoogleMap
            :mapheight="isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 50px)'"
            :city="mapSearchText"
            @getClinicsList="setClinicLists"
            :isMobile="isMobile"
            :consultationClinic="consultationClinic"
            :showGoogleMap="showGoogleMap && showClinicType == 'map'"
          />
        </div>
      </div>

      <div
        class="border-t-2 border-light-silver w-full bg-grey-light absolute bottom-0 p-3 flex justify-end text-secondary underline font-bold"
      >
        <NuxtLink @click="closeModal"> {{ $t("common.cancel") }} </NuxtLink>
      </div>
      <!-- /End replace -->
    </CommonSliderDrawer>
  </div>
</template>

<script setup>
const emit = defineEmits([
  "closeModal",
  "searchText",
  "clearSearch",
  "onTabClick",
]);
const props = defineProps({
  openDrawer: {
    type: Boolean,
    default: false,
  },
  consultationClinic: {
    type: Boolean,
  },

  serviceTypeSelected: {
    type: Object,
  },
  showClinicType: {
    type: String,
  },
});
const isMobile = useScreenSize("sm");
const open = ref(props.openDrawer);
const showClose = ref(false);
const mapSearchText = ref("all");
const clinicLists = ref([]);
const showGoogleMap = ref(true);

watch(
  () => props.openDrawer,
  (newData) => {
    showGoogleMap.value = props.showClinicType === "list" ? false : true;
    open.value = newData;
  }
);

const testName = computed(() => {
  return `${props.serviceTypeSelected.topics[0].title} ${props.serviceTypeSelected.types[0].title}`;
});

function closeModal() {
  onCloseClicked();
  emit("closeModal");
}

function onSearchClicked(data) {
  mapSearchText.value = data;
  showClose.value = true;
}

function onCloseClicked() {
  mapSearchText.value = "clearSearch";
  showClose.value = false;
}

function setClinicLists(data) {
  clinicLists.value = data;
}

function onTabClicked() {
  showGoogleMap.value = !showGoogleMap.value;
}
</script>

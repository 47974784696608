<template>
  <div class="relative flex-1">
    <div
      class="z-10 flex flex-col md:w-[496px] mx-4 relative -mb-10 mt-10 md:mt-4"
    >
      <GetStartedSectionsSexualHealth v-show="selectedSectionSlug === 'sexual-health'" />
      <GetStartedSectionsConsultation v-show="selectedSectionSlug === 'consultation'" />
      <GetStartedSectionsHealthAndWellness v-show="selectedSectionSlug === 'health-and-wellness'" />
    </div>

    <ClientOnly>
      <GetStartedModal
        :serviceTopicLists="[]"
        :titleTopicBox="{ title: '--', slug: '--' }"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { GetStartedFlow } from './types';

const route = useRoute();

/**
 * Currently focused section.
 */
const selectedSectionSlug = computed(() => route.params.section as GetStartedFlow);
</script>

<template>
  <div
    class="flex items-center p-4 cursor-pointer bg-white rounded-lg shadow-default md:mb-24 md:mt-0 mt-4"
    v-if="props.service"
  >
    <div class="flex w-8 h-8 flex-none items-start shrink-0 justify-center">
      <NuxtImg
        loading="lazy"
        :src="props.service.icon.permalink"
        alt=""
        width="36"
        height="36"
      />
    </div>
    <div class="flex flex-col flex-1 mx-3">
      <div class="font-bold">
        {{ props.service.title }}
      </div>
    </div>
    <button
      type="button"
      class="text-gray-400 bg-light-silver shrink-0 rounded-full fill-secondary p-0.5 ml-auto md:inline-flex items-center"
      @click="handleGoBack"
    >
      <svg
        aria-hidden="true"
        class="w-5 h-5"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { GetStartedService } from "./types";

interface GetStartedSelectedServiceCardProps {
  service: GetStartedService;
}

const props = defineProps<GetStartedSelectedServiceCardProps>();

const route = useRoute();
const selectedSectionSlug = computed(() => route.params.section);

function handleGoBack() {
  const locale = switchBlogLocale();
  const nextPath = `/${locale}/get-started/${selectedSectionSlug.value}`;
  return navigateTo({
    path: nextPath,
    force: true,
  });
}
</script>

<template>
  <CommonSliderDrawerFullPage
    :openDrawer="isOpen"
    :showFullPage="true"
    @closeModal="handleCloseModal"
  >
    <div class="flex md:h-full flex-col h-screen md:pt-12 pt-8 bg-white">
      <div>
        <CommonBreadCrumbs class="fixed" />
        <div
          class="flex md:flex-row flex-col md:max-w-7xl md:mx-auto md:relative px-4 md:mt-4 mt-2 bg-white"
        >
          <div
            class="cursor-pointer flex leading-7 pb-2"
            @click="handleCloseModal()"
          >
            <NuxtImg
              loading="lazy"
              class="mx-2 w-2"
              src="/icons/left-arrow.svg"
              alt="back left arrow icon"
              width="8"
              height="13"
            />
            {{ $t("common.btnBack") }}
          </div>

          <ClinicSelectedBlock
            v-if="enableMap"
            :item="selectedService"
          />
        </div>
        <div class="bg-secondary-lighter md:pb-0 pb-4">
          <div v-if="enableMap">
            <Transition name="slide-fade-back">
              <div
                class="md:relative md:overflow-hidden md:h-full h-[calc(100vh-100px)] overflow-y-auto"
                v-if="stepIndex === 'select_clinic'"
              >
                <div
                  class="flex md:flex-row flex-col md:max-w-7xl md:mx-auto md:h-[calc(100vh-100px)]"
                >
                  <div
                    class="md:w-[40%] md:pt-14 md:mx-4 relative"
                  >
                    <h2 class="md:text-left text-center my-10 md:mt-0 md:mb-4">
                      {{ $t("getStarted.chooseYourClinic") }}
                    </h2>
                    <ClinicMapList
                      :clinicLists="clinicLists"
                      :showSearchBox="true"
                      :popularCityList="[]"
                      listHeight="h-full md:h-[calc(100vh-320px)]"
                      @searchText="(data) => (mapSearchText = data)"
                      @clearSearch="clearClinicLists"
                      @onTabClick="(data) => (showGoogleMap = data)"
                      :isMobile="isMobile"
                    />
                  </div>
                  <div
                    class="md:absolute md:w-[60%] map-container md:right-0"
                    id="clinicSearchMap"
                  >
                    <ClinicGoogleMap
                      class="md:ml-8 large-desktop:ml-15 2xl:ml-20"
                      onSearchShowAllCity
                      :categoryId="3053"
                      :serviceType="selectedEntity.slug"
                      @getClinicsList="setClinicLists"
                      :city="mapSearchText"
                      :mapheight="
                        isMobile ? 'calc(100vh - 220px)' : 'calc(100vh - 80px)'
                      "
                      :consultationClinic="false"
                      :isMobile="isMobile"
                      :showGoogleMap="showGoogleMap"
                    />
                  </div>
                </div>
              </div>
            </Transition>
            <Transition name="slide-fade">
              <div
                v-if="stepIndex === 'show_tests'"
                class="flex flex-col h-[calc(100vh-100px)] bg-secondary-lighter pt-10 mt-6 md:mt-0 overflow-y-auto"
              >
                <CommonPanelContent
                  :hideChecked="true"
                  startPoint="get-started"
                />
              </div>
            </Transition>
          </div>
          <div
            v-else
            class="bg-secondary-lighter h-[calc(100vh-100px)] overflow-y-auto"
          >
            <NuxtLayout name="center" class="py-12 space-y-5 md:pb-32 md:px-24">
              <h2 class="text-left border-b-2 pb-4">
                How would you like to speak to your doctor?
              </h2>
              <div class="grid lg:grid-cols-3 gap-8">
                <div
                  v-for="serviceList in serviceTopicLists"
                  :key="serviceList.id"
                >
                  <ServicesTopicList
                    @showDrawer="() => (openDrawer = true)"
                    :item="serviceList"
                    :serviceTypeSelected="selectedEntity"
                    :titleTopicBox="titleTopicBox"
                  />
                </div>
              </div>
            </NuxtLayout>
            <ServicesSliderList
              :showClinicType="selectedEntity.clinic_view"
              :consultationClinic="true"
              @closeModal="() => (openDrawer = false)"
              :openDrawer="openDrawer"
              :serviceTypeSelected="selectedEntity"
            />
          </div>
        </div>
      </div>
    </div>
  </CommonSliderDrawerFullPage>
</template>
<script setup>
import { useMapStore } from "@/stores/map";

const props = defineProps({
  serviceTopicLists: Object,
  titleTopicBox: Object
});

const route = useRoute();
const isMobile = useScreenSize("sm");
const mapSearchText = ref("all");
const openDrawer = ref(false);
const showGoogleMap = ref(false);
const clinicLists = ref([]);
const mapStore = useMapStore();

const getStartedModalStore = useGetStartedModalStore();
const enableMap = computed(() => getStartedModalStore.enableMap);
const stepIndex = computed(() => getStartedModalStore.stepIndex);
const isOpen = computed(() => getStartedModalStore.isOpen);
const selectedEntity = computed(() => getStartedModalStore.selectedEntity);

const selectedService = computed(() => {
  switch(true) {
    case Boolean(route.query.slug):
      return mapStore.getSelectedClinic;

    case Boolean(route.params.section === 'everywhere-nurse-service'):
      return {
        title: 'Everywhere Nurse service',
        slug: 'everywhere-nurse-service',
        content: route.query.searchTerm,
      };

    default:
      return selectedEntity.value;
  }
});


function setClinicLists(data) {
  if (mapSearchText.value && mapSearchText.value != "all") {
    clinicLists.value = data;
  } else {
    clinicLists.value = [];
  }
}
watch(
  () => props.showModal,
  (value) => {
    if (!value) onBack();
  }
);

function onBack() {
	checkoutStore.removeTestLists()
  mapSearchText.value = "all";
  emit("closeModalOnBack");
}

function clearClinicLists() {
  clinicLists.value = [];
}

async function handleCloseModal() {
  /**
   * Sync route params.
   */
  await navigateTo({
    params: route.params,
  });

  /**
   * Close modal if we are already at the first step.
   * Or everywhere nurse service modal modifier.
   */
  if (route.params.service === 'everywhere-nurse-service' || getStartedModalStore.stepIndex === "select_clinic")
    getStartedModalStore.close();

  /**
   * Navigate back to the first step.
   */
  getStartedModalStore.navigate('select_clinic');

  /**
   * Reset search.
   */
  mapSearchText.value = "all";
}
</script>

<style scoped>
.slide-fade-back-enter-active,
.slide-fade-back-leave-active {
  position: fixed;
  right: 0;
  left: 0;
  transition: all 350ms ease-in-out;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  position: fixed;
  right: 0;
  left: 0;
  transition: all 350ms ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(50%);
}
.slide-fade-back-enter-from,
.slide-fade-back-leave-to {
  transform: translateX(-50%);
}
</style>

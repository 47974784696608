<template>
  <div>
    <Transition
      :name="`sections-slide-${selectedSection ? 'out' : 'in'}`"
      @before-enter="displayServicesSlot = false"
      @after-leave="displayServicesSlot = true"
    >
      <slot :key="sectionKey" name="sections" />
    </Transition>

    <Transition :name="`services-slide-${serviceTransitionMode}`">
      <slot :key="serviceKey" v-if="displayServicesSlot" name="services" />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { OptionalString } from "~/types/general";

const route = useRoute();

/**
 * Handles transitions after route changes.
 */
const sectionKey = computed(() => route.params.section);
const serviceKey = computed(() => route.params.service);

/**
 * Get the current selected section.
 */
const selectedSection = computed(() => route.params.section as OptionalString);

/**
 * Prepare transitions & interactivities.
 */
const displayServicesSlot = ref<boolean>(true);

const serviceTransitionMode = ref<'in' | 'out'>('in');
onBeforeRouteUpdate((_, from) => {
  serviceTransitionMode.value = from.params.service ? 'out' : 'in';
});
</script>

<style scoped lang="scss">

// Transitions keyframes for mobile.
@media (max-width: 767px) {
  .sections-slide-in-enter-active,
  .sections-slide-out-enter-active,
  .services-slide-in-enter-active,
  .services-slide-out-enter-active {
    transition: transform 500ms ease-in-out;
  }

  .sections-slide-in-enter-from,
  .sections-slide-in-leave-to {
    transform: translateY(-2rem);
  }

  .sections-slide-out-enter-from,
  .sections-slide-out-leave-to {
    transform: translateY(2rem);
  }

  .services-slide-in-enter-from,
  .services-slide-in-leave-to {
    transform: translateY(2rem);
  }

  .services-slide-out-enter-from,
  .services-slide-out-leave-to {
    transform: translateY(-2rem);
  }
}

// Transitions keyframes for tablet/desktop
@media (min-width: 768px) {
  .sections-slide-in-enter-active,
  .sections-slide-out-enter-active,
  .services-slide-in-enter-active,
  .services-slide-out-enter-active {
    transition: transform 500ms ease-in-out;
  }

  .sections-slide-in-enter-from,
  .sections-slide-in-leave-to {
    transform: translateX(2rem);
  }

  .sections-slide-out-enter-from,
  .sections-slide-out-leave-to {
    transform: translateX(-2rem);
  }

  .services-slide-in-enter-from,
  .services-slide-in-leave-to {
    transform: translateY(2rem);
  }

  .services-slide-out-enter-from,
  .services-slide-out-leave-to {
    transform: translateY(-2rem);
  }
}


</style>

<template>
  <div
    class="pt-4 basis-full"
    :class="{
      'pt-20 md:pt-4': !!sectionSlug,
    }"
  >
    <div
      class="flex gap-4 flex-wrap md:flex-row flex-col bg-primary-600 md:px-12 lg:px-12 md:pt-8 md:rounded-md relative isolate"
    >
      <NuxtImg
        loading="lazy"
        src="/img/get-started.svg"
        v-if="!sectionSlug"
        class="absolute right-0 bottom-0 hidden md:block -z-1 pointer-events-none"
        alt="get-started-img"
        width="711"
        height="466"
      />

      <h2
        v-if="!props.isGetStarted"
        class="basis-full text-2xl mt-7 inline md:hidden"
      >
        {{ $t("getStarted.howWeCanHelp") }}
      </h2>
     <ClientOnly> 
      <GetStartedSectionTransitionGroup
        class="flex flex-nowrap md:flex-row flex-col basis-full -mx-1 px-1 mb-16 pb-4 gap-4"
        :class="sectionSlug != '' ? ' md:ml-4-start ' : ''"
      >
        <template v-slot:sections>
          <div
            class="shrink-0 relative grid md:grid-flow-col gap-4 ml-0"
            :class="{
              '-translate-y-24 md:translate-y-0': !!sectionSlug,
            }"
          >
            <div
              v-for="(item, index) in displayableCategories"
              :key="index"
              class="z-10 md:z-1 transition-all duration-300 ease-in-out w-full"
            >
              <article :class="sectionSlug !== item.slug ? 'mt-12' : 'mt-6'">
                <div
                  v-if="sectionSlug === item.slug"
                  class="md:hidden cursor-pointer flex leading-7 md:px-0 px-4"
                  @click="abortSection"
                >
                  <NuxtImg
                    loading="lazy"
                    class="mx-2 w-2"
                    src="/icons/left-arrow.svg"
                    alt="back left arrorw icon"
                    width="8"
                    height="28"
                  />
                  Back
                </div>
                <NuxtLinkLocale
                  :locale="switchBlogLocale()"
                  :to="
                    !serviceSlug && sectionSlug
                      ? `/${path}`
                      : `/${path}/${item.slug}`
                  "
                  class="flex getstartedCard flex-row-reverse px-4 md:px-0 md:flex-col md:min-h-[18rem] text-start items-center relative rounded-lg group"
                  :class="{
                    'z-10 mt-3 md:mt-[3rem]': sectionSlug === item.slug,
                  }"
                >
                  <span
                    class="pointer-events-none absolute z-1 right-[2.5em] md:right-1/2 md:translate-x-1/2 p-3 w-16 -top-9 md:left-auto md:w-[5rem] aspect-square rounded-[50%] grid place-items-center shadow-[0_-12px_1rem_-0.9rem_rgb(0_0_0_/_40%)_inset]"
                    :class="
                      sectionSlug === item.slug
                        ? `md:bg-primary-600 bg-white`
                        : 'bg-primary-600'
                    "
                  >
                    <NuxtImg
                      width="50"
                      class="imageBlock w-10/12"
                      :class="
                        sectionSlug === item.slug
                          ? `fill-svg-secondary`
                          : 'fill-svg-primary'
                      "
                      :src="item.icon.permalink"
                    />
                  </span>
                  <CommonRippleBox
                    ripple-position="top-center"
                    class="shadow-lg flex flex-col flex-1 p-4 md:px-4 px-8 items-start md:items-center"
                    :class="
                      sectionSlug === item.slug
                        ? 'h-[7em] w-[22rem] md:w-[18rem] lg:w-[16rem] md:h-[12] bg-secondary text-white'
                        : 'bg-white w-full md:w-[16rem] h-[12em] md:h-auto'
                    "
                  >
                    <h3 class="mt-8 text-[1.4em] text-center md:mt-12">
                      {{ item.title }}
                    </h3>
                    <p
                      class="mt-3 text-sm text-start pr-7 md:pr-0"
                      :class="{
                        'hidden md:block': sectionSlug === item.slug,
                      }"
                      v-html="item.intro_text"
                    ></p>

                    <span
                      class="flex mt-auto items-center justify-start w-full"
                    >
                      <img
                        class="ml-auto mt-auto"
                        :class="{
                          'hidden md:inline-block md:ml-0 mr-4 rotate-180':
                            sectionSlug === item.slug,
                        }"
                        src="~/assets/icons/mask.svg"
                        alt="mask icon"
                        width="30"
                        height="30"
                      />
                      <p
                        v-if="sectionSlug === item.slug"
                        class="hidden md:inline text-xs font-light"
                      >
                        {{ $t("getStarted.return") }}
                      </p>
                    </span>
                  </CommonRippleBox>
                </NuxtLinkLocale>
              </article>
            </div>
          </div>
        </template>

        <template v-if="sectionSlug" v-slot:services>
          <div
            class="mx-auto"
            :class="{
              '-translate-y-24 md:translate-y-0': !!sectionSlug,
            }"
          >
            <GetStartedServicesGroup />
          </div>
        </template>
      </GetStartedSectionTransitionGroup>
    </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OptionalString } from "~/types/general";

interface HelpYouProps {
  isGetStarted?: boolean;
}

const path = `get-started`;
const props = withDefaults(defineProps<HelpYouProps>(), {
  isGetStarted: false,
});

const route = useRoute();

const sectionSlug = computed(() => route.params.section as OptionalString);
const serviceSlug = computed(() => route.params.service as OptionalString);

const sections = await useGetStartedSections();
const displayableCategories = computed(() =>
  sectionSlug.value
    ? sections.filter((category) => category.slug === sectionSlug.value)
    : sections
);

async function abortSection() {
  const nextUri = ["", switchBlogLocale(), path].join("/");
  return navigateTo(nextUri);
}
</script>

<style scoped>
:deep(.iconColored > svg) {
  fill: #931363;
}
:deep(span > svg) {
  width: 2.25rem;
}
@media screen and (min-width: 768px) {
  :deep(span > svg) {
    width: 3rem;
  }
}

.fill-svg-primary {
  filter: invert(43%) sepia(64%) saturate(1759%) hue-rotate(151deg)
    brightness(90%) contrast(101%) !important;
}
.fill-svg-secondary {
  filter: invert(11%) sepia(100%) saturate(3791%) hue-rotate(311deg)
    brightness(83%) contrast(91%) !important;
}
.getstartedCard:hover img.imageBlock {
  filter: invert(11%) sepia(100%) saturate(3791%) hue-rotate(311deg)
    brightness(83%) contrast(91%) !important;
}
</style>
